import { useCallback } from 'react';

import { toast } from '@backyard-ui/core';
import { type UseMutationOptions, useMutation } from '@tanstack/react-query';

import { Services } from '@/infra/services';
import { type Response } from '@/infra/services/wishlist/create-wishlist';
import { type WishlistError } from '@/infra/services/wishlist/wishlist-error';

import { type Item } from '../model';
import { FEEDBACK_MESSAGES } from '../utils';

import { useQueryActions } from './';

type MutationParams = {
  name: string;
  item: Item;
};

type MutationError = { json: () => Promise<WishlistError> };

type Options = UseMutationOptions<Response, MutationError, MutationParams> & {
  closeDrawer?: () => void;
};

const useCreateWishlist = (options?: Options) => {
  const { updateQueryAddItem } = useQueryActions();

  const { mutate, ...query } = useMutation({
    mutationFn: async ({ item, name }) => {
      return await Services.Wishlist.createWishlist({
        name,
        [item.type]: [{ id: item.id }],
      });
    },
    ...options,
    onSuccess: (data, variables, context) => {
      updateQueryAddItem(variables.item);

      toast(
        FEEDBACK_MESSAGES.create(variables.item.type, data.data.name).success
      );

      options?.onSuccess?.(data, variables, context);
    },

    onError: async (error, variables, context) => {
      // Criar tratativa de erro no fetch http client.
      const errorData = await error.json().catch(() => null);
      const errorCode = errorData?.errors?.[0]?.code;

      if (errorCode === 'name') {
        toast(
          FEEDBACK_MESSAGES.create(variables.item.type, variables.name).error
            .name
        );
      } else {
        toast(FEEDBACK_MESSAGES.create(variables.item.type).error.default);
        options?.closeDrawer?.();
      }

      options?.onError?.(error, variables, context);
    },
  });

  const create = useCallback(
    (item: Item, name: string) => mutate({ item, name }),
    [mutate]
  );

  return {
    ...query,
    create,
  };
};

export default useCreateWishlist;
