import { tv } from 'tailwind-variants';

export const styles = tv({
  base: `w-4`,
  variants: {
    variant: {
      dark: 'text-black',
      primary: 'text-green-500',
    },
  },
  defaultVariants: {
    variant: 'primary',
  },
});
