import { useCallback } from 'react';

import { toast } from '@backyard-ui/core';
import { type UseMutationOptions, useMutation } from '@tanstack/react-query';

import { Services } from '@/infra/services';
import { type WishlistError } from '@/infra/services/wishlist/wishlist-error';

import { type Item } from '../model';
import { FEEDBACK_MESSAGES } from '../utils';

import useQueryActions from './useQueryActions';

type MutationParams = {
  item: Item;
};

type Options = UseMutationOptions<void, WishlistError, MutationParams>;

const useRemoveItemFromWishlist = (options?: Options) => {
  const { updateQueryRemoveItem } = useQueryActions();

  const { mutate, ...query } = useMutation({
    mutationFn: async ({ item }) => {
      return await Services.Wishlist.removeItemFromWishlist(item);
    },
    ...options,
    onSuccess: (data, variables, context) => {
      updateQueryRemoveItem(variables.item);

      toast(FEEDBACK_MESSAGES.remove(variables.item.type).success);

      options?.onSuccess?.(data, variables, context);
    },

    onError: (error, variables, context) => {
      toast(FEEDBACK_MESSAGES.remove(variables.item.type).error);

      options?.onError?.(error, variables, context);
    },
    onSettled: console.log,
  });

  const remove = useCallback((item: Item) => mutate({ item }), [mutate]);

  return {
    ...query,
    remove,
  };
};

export default useRemoveItemFromWishlist;
