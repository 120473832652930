import { type Item } from '../model';

import { buildFeedbackMessages } from './';

export const FEEDBACK_MESSAGES = {
  getWishlists: () => ({
    error: buildFeedbackMessages({
      status: 'error',
      message:
        'Não conseguimos carregar suas listas de desejos. Por favor, tente novamente',
    }),
  }),

  remove: (type: Item['type']) => ({
    success: buildFeedbackMessages({
      status: 'success',
      message: ` ${
        type === 'products' ? 'Produto' : 'Conteúdo'
      } removido da lista de favoritos`,
    }),
    error: buildFeedbackMessages({
      status: 'error',
      message: `Não conseguimos remover o ${
        type === 'products' ? 'produto' : 'conteúdo'
      } da lista de favoritos`,
    }),
  }),

  create: (type: Item['type'], name?: string) => ({
    success: buildFeedbackMessages({
      status: 'success',
      message: `${
        type === 'products' ? 'Produto' : 'Conteúdo'
      } adicionado à nova lista "${name}"`,
    }),
    error: {
      name: buildFeedbackMessages({
        status: 'error',
        message: `Você já possui uma lista chamada "${name}". Por favor, escolha outro nome`,
      }),
      default: buildFeedbackMessages({
        status: 'error',
        message: `Não conseguimos adicionar o ${
          type === 'products' ? 'produto' : 'conteúdo'
        } à lista de favoritos`,
      }),
    },
  }),

  add: (type: Item['type'], name?: string) => ({
    success: buildFeedbackMessages({
      status: 'success',
      message: `${
        type === 'products' ? 'Produto' : 'Conteúdo'
      } adicionado a "${name}"`,
    }),
    error: buildFeedbackMessages({
      status: 'error',
      message: `Não conseguimos adicionar o ${
        type === 'products' ? 'produto' : 'conteúdo'
      } à lista de favoritos`,
    }),
  }),
};
