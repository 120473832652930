'use client';

import { memo, useState } from 'react';

import {
  Button,
  Text,
  Drawer,
  Stack,
  Skeleton,
  ScrollArea,
  toast,
} from '@backyard-ui/core';

import {
  useGetWishlistsInfinite,
  useAddItemToWishlist,
  useCreateWishlist,
} from '../../hooks';
import { type Trigger, type Item } from '../../model';
import { FEEDBACK_MESSAGES } from '../../utils';
import { CreateButton } from '../CreateButton';
import { ListItem } from '../ListItem';

import { styles } from './WishlistDrawer.styles';

const LISTS_PER_PAGE = 10;

interface WishlistDrawerProps {
  item: Item;
  trigger: Trigger;
  isDisabled: boolean;
  ariaLabel: string;
  onAdd?: (listName: string) => void;
  onCreate?: (listName: string) => void;
}

function WishlistDrawer(props: Readonly<WishlistDrawerProps>) {
  const {
    item,
    trigger: TriggerButton,
    ariaLabel,
    isDisabled,
    onAdd,
    onCreate,
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isInitialLoading,
    isFetchingNextPage,
  } = useGetWishlistsInfinite({
    enabled: isOpen,
    onError: () => isOpen && toast(FEEDBACK_MESSAGES.getWishlists().error),
  });

  const {
    add,
    checkAddingListSlug,
    isLoading: isAdding,
  } = useAddItemToWishlist({
    onSuccess: (data) => onAdd?.(data.data.name),
    onSettled: () => setIsOpen(false),
  });

  const { create, isLoading: isCreating } = useCreateWishlist({
    onSuccess: (data) => onCreate?.(data.data.name),
    closeDrawer: () => setIsOpen(false),
  });

  const classNames = styles();

  return (
    <Drawer.Root size="lg" isOpen={isOpen} onOpenChange={setIsOpen}>
      <Drawer.Trigger asChild>
        <TriggerButton
          isInWishlist={false}
          aria-label={ariaLabel}
          isDisabled={isDisabled}
          onClick={() => setIsOpen(true)}
        />
      </Drawer.Trigger>

      <Drawer.Content>
        <div className={classNames.title()}>
          <Drawer.Title>Adicionar à lista de favoritos</Drawer.Title>
        </div>

        <Drawer.Description>
          <Stack
            direction="column"
            justify="flex-start"
            spacing="4"
            className={classNames.list()}
          >
            <CreateButton
              isLoading={isCreating}
              isDisabled={isAdding}
              onCreate={(name) => {
                create(item, name);
              }}
            />
            <Text>Adicionar a uma lista existente</Text>

            {isInitialLoading ? (
              <Stack direction="column">
                {Array.from({ length: LISTS_PER_PAGE })
                  .fill('')
                  .map((_, index) => (
                    <Skeleton key={index} height="48px" width="100%" />
                  ))}
              </Stack>
            ) : (
              <ScrollArea UNSAFE_className={classNames.scrollArea()}>
                <Stack direction="column" data-testid="wishlist-drawer-list">
                  {data?.pages?.map((page, index) => {
                    if (page.data.length === 0) {
                      return (
                        <Text size="md" key={index}>
                          Você ainda não possui nenhuma lista.
                        </Text>
                      );
                    }

                    return page.data.map((listItem) => (
                      <ListItem
                        key={listItem.id}
                        name={listItem.name}
                        products={listItem.products}
                        contents={listItem.contents}
                        picture={listItem.pictures?.list}
                        isDisabled={isAdding || isCreating}
                        isLoading={
                          isAdding && checkAddingListSlug(listItem.slug)
                        }
                        onAdd={() => {
                          add(item, listItem.slug);
                        }}
                      />
                    ));
                  })}

                  {hasNextPage && (
                    <div className={classNames.seeMore()}>
                      <Button
                        variant="ghost"
                        size="xs"
                        disabled={isAdding || isCreating}
                        isLoading={isFetchingNextPage}
                        onClick={() => fetchNextPage()}
                        isFullWidth
                      >
                        Ver mais
                      </Button>
                    </div>
                  )}
                </Stack>
              </ScrollArea>
            )}
          </Stack>
        </Drawer.Description>
        <Drawer.CloseButton />
      </Drawer.Content>
    </Drawer.Root>
  );
}

export default memo(WishlistDrawer);
