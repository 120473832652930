import { useCallback } from 'react';

import { useQueryClient } from '@tanstack/react-query';

import { type Response as GetWishlistItemsIdsResponse } from '@/infra/services/wishlist/get-wishlist-items-ids';
import { useGlobalStore } from '@/presenters/store/global';

import { type Item } from '../model';

const useQueryActions = () => {
  const queryClient = useQueryClient();

  const userId = useGlobalStore((state) => state.user.resources?.id);

  const updateQueryAddItem = useCallback(
    (item: Item) => {
      const { type, id } = item;

      queryClient.setQueryData<GetWishlistItemsIdsResponse>(
        ['wishlist', userId],
        (prev) => {
          if (!prev) return prev;

          const previousData = prev?.data ?? {
            products: [],
            contents: [],
          };

          return {
            data: {
              ...prev.data,
              [type]: [...previousData[type], id],
            },
          };
        }
      );
    },
    [queryClient, userId]
  );

  const updateQueryRemoveItem = useCallback(
    (item: Item) => {
      const { type, id } = item;

      queryClient.setQueryData<GetWishlistItemsIdsResponse>(
        ['wishlist', userId],
        (prev) => {
          if (!prev) return prev;

          return {
            data: {
              ...prev.data,
              [type]: [...prev.data[type]].filter((item) => item !== id),
            },
          };
        }
      );
    },
    [queryClient, userId]
  );

  return {
    updateQueryAddItem,
    updateQueryRemoveItem,
  };
};

export default useQueryActions;
