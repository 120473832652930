import NextImage, { type ImageProps, type ImageLoaderProps } from 'next/image';

const normalizeSrc = (src: string) => {
  return src.startsWith('/') ? src.slice(1) : src;
};

const cloudflareLoader = ({ src, width, quality }: ImageLoaderProps) => {
  const params = [`width=${width}`];

  if (quality) {
    params.push(`quality=${quality}`);
  }

  const paramsString = params.join('&');

  return `${normalizeSrc(src)}?${paramsString}`;
};

function Image(props: ImageProps) {
  return <NextImage loader={cloudflareLoader} {...props} />;
}

export default Image;
