'use client';

import { useRemoveItemFromWishlist } from '../../hooks';
import { type Trigger, type Item } from '../../model';

interface WishlistDrawerProps {
  item: Item;
  trigger: Trigger;
  isDisabled: boolean;
  ariaLabel: string;
  onRemove?: () => void;
}

function RemoveFromWishlistButton(props: Readonly<WishlistDrawerProps>) {
  const {
    item,
    trigger: TriggerButton,
    isDisabled,
    onRemove,
    ariaLabel,
  } = props;

  const { remove } = useRemoveItemFromWishlist({ onSuccess: onRemove });

  return (
    <TriggerButton
      aria-label={ariaLabel}
      isDisabled={isDisabled}
      onClick={() => remove(item)}
      isInWishlist
    />
  );
}

export default RemoveFromWishlistButton;
