import { clamp } from 'lodash';

interface GetDecimalsFromRating {
  rating: number;
  index: number;
}

const getDecimalsFromRating = (props: GetDecimalsFromRating) => {
  const { rating, index } = props;

  return clamp(rating - index, 0, 1);
};

export default getDecimalsFromRating;
