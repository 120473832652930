import { tv } from 'tailwind-variants';

export const styles = tv({
  base: `
    relative
  `,
  slots: {
    title: `
      max-w-[90%]
    `,
    list: `
      h-full
      w-full
      overflow-y-hidden
    `,
    seeMore: `
      w-full
      p-0.5
    `,
    scrollArea: `
      h-full
      pr-4
    `,
  },
});
