import { useQuery } from '@tanstack/react-query';

import { Services } from '@/infra/services';
import { useGlobalStore } from '@/presenters/store/global';

import { type Item } from '../model';

function useWishlistItem(item: Item) {
  const userId = useGlobalStore((state) => state.user.resources?.id);

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['wishlist', userId],
    queryFn: Services.Wishlist.getWishlistItemsIds,
    enabled: Boolean(userId),
  });

  const isInWishlist =
    item.type === 'products'
      ? data?.data.products.includes(item.id)
      : data?.data.contents.includes(item.id);

  return {
    isInWishlist: isInWishlist ?? false,
    isLoading: isLoading || isFetching,
  };
}

export default useWishlistItem;
