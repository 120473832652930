import { type ToastProps } from '@backyard-ui/core';

type Status = 'success' | 'error';

type StatusProperties = {
  [key in Status]: {
    appearance: ToastProps['appearance'];
    title: ToastProps['title'];
  };
};

const STATUS_PROPERTIES: StatusProperties = {
  success: { appearance: 'success', title: 'Sucesso!' },
  error: { appearance: 'critical', title: 'Erro!' },
};

export interface Args {
  status: Status;
  message?: string;
}

export const buildFeedbackMessages = ({
  status,
  message,
}: Args): ToastProps => {
  const toastOptions: ToastProps = {
    ...STATUS_PROPERTIES[status],
    variant: 'subtle',
    isClosable: true,
    description: message || '',
  };

  return toastOptions;
};
