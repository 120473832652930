import { useCallback } from 'react';

import { toast } from '@backyard-ui/core';
import { type UseMutationOptions, useMutation } from '@tanstack/react-query';

import { Services } from '@/infra/services';
import { type Response } from '@/infra/services/wishlist/add-item-to-wishlist';
import { type WishlistError } from '@/infra/services/wishlist/wishlist-error';

import { type Item } from '../model';
import { FEEDBACK_MESSAGES } from '../utils';

import { useQueryActions } from './';

type MutationParams = {
  item: Item;
  slug: string;
};

type Options = UseMutationOptions<Response, WishlistError, MutationParams>;

const useAddItemToWishlist = (options?: Options) => {
  const { updateQueryAddItem } = useQueryActions();

  const { mutate, ...query } = useMutation<
    Response,
    WishlistError,
    MutationParams
  >({
    mutationFn: async ({ item, slug }) => {
      return Services.Wishlist.addItemToWishlist({ ...item, slug });
    },
    ...options,

    onSuccess: (data, variables, context) => {
      updateQueryAddItem(variables.item);

      toast(FEEDBACK_MESSAGES.add(variables.item.type, data.data.name).success);

      options?.onSuccess?.(data, variables, context);
    },

    onError: (error, variables, context) => {
      toast(FEEDBACK_MESSAGES.add(variables.item.type).error);

      options?.onError?.(error, variables, context);
    },
  });

  const add = useCallback(
    (item: Item, slug: string) => mutate({ item, slug }),
    [mutate]
  );

  const checkAddingListSlug = useCallback(
    (slug: string) => {
      return query.variables?.slug === slug;
    },
    [query.variables?.slug]
  );

  return {
    ...query,
    add,
    checkAddingListSlug,
  };
};

export default useAddItemToWishlist;
