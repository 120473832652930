import {
  type UseInfiniteQueryOptions,
  useInfiniteQuery,
} from '@tanstack/react-query';

import { Services } from '@/infra/services';
import { type Response } from '@/infra/services/wishlist/get-wishlists';

const LISTS_PER_PAGE = 10;

type InfiniteOptions = Omit<
  UseInfiniteQueryOptions<Response, Error, Response>,
  'queryKey' | 'queryFn' | 'getNextPageParam'
>;

function useGetWishlistsInfinite(options?: InfiniteOptions, perPage?: number) {
  return useInfiniteQuery<Response, Error, Response>({
    ...options,
    queryKey: ['wishlists-drawer'],
    queryFn: ({ pageParam = 1 }) =>
      Services.Wishlist.getWishlists({
        page: pageParam,
        perPage: perPage || LISTS_PER_PAGE,
      }),
    getNextPageParam: ({ meta }) => {
      if (meta.page < meta.pages) {
        return meta.page + 1;
      }

      return undefined;
    },
  });
}

export default useGetWishlistsInfinite;
