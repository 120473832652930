import { useQuery } from '@tanstack/react-query';

import { Services } from '@/infra/services';
import { type ItemId } from '@/infra/services/catalog/model';

const useGetSellers = (productId: ItemId) => {
  const result = useQuery({
    queryKey: ['get-sellers', productId],
    queryFn: async () => {
      const { data } = await Services.Catalog.Product.getSellers({ productId });

      return data ?? [];
    },
  });

  return result;
};

export default useGetSellers;
