import { useQuery } from '@tanstack/react-query';

import { Services } from '@/infra/services';
import { type ItemId } from '@/infra/services/catalog/model';

const useRatings = (itemsIds: ItemId[]) => {
  const result = useQuery({
    queryKey: ['get-rating', itemsIds],
    queryFn: () =>
      Services.Catalog.Review.getRatings({ productsLms: itemsIds }),
  });

  return result;
};

export default useRatings;
